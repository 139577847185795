import { cloneDeep, set } from "lodash";
import i18n from '@biuwer/core/src/i18n';

// Libs
import { getDateInputFormat, dateValueIsValid, numberValueIsValid } from "@biuwer/filters/src/filters-lib";
import { getTimeMetricValue, getTimeExpressionFromParts } from "@biuwer/common/src/libs/time-lib";
import { getDateTimeFormatOptions, getTimeLevelOptions } from "@biuwer/common/src/libs/dates-lib";

// Actions
import {
    // Filters editor dialog metadata
    INITIALIZE_FILTERS_EDITOR_DIALOG,
    SET_FILTERS,
    SET_SELECTED_FILTER_ID,
    SET_PAGE_CARDS_METADATA,
    SET_CARD_METADATA,
    // General
    SET_NAME,
    SET_FILTER_TYPE,
    SET_STAGE,
    SET_EDITABLE,
    SET_MANDATORY,
    SET_VISIBLE,
    SET_MULTI_VALUE,
    SET_ORDER,
    SET_TRANSLATIONS,
    // Custom list
    SET_USE_CUSTOM_LIST,
    SET_CUSTOM_LIST,
    // Origin
    SET_DATAMODEL,
    SET_DATASET,
    SET_DATA_FIELD,
    // Default expression
    SET_DEFAULT_EXPRESSION_ALL_VALUES,
    SET_DEFAULT_EXPRESSION_VALUES,
    SET_DEFAULT_EXPRESSION_VALUE,
    SET_DEFAULT_EXPRESSION_FROM_VALUE,
    SET_DEFAULT_EXPRESSION_TO_VALUE,
    SET_DEFAULT_EXPRESSION_OPERATION,
    SET_DEFAULT_EXPRESSION_DATE_LEVEL,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_1,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_2,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_3,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_1,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_2,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_3,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_1,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_2,
    SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_3,
    SET_DEFAULT_EXPRESSION_OPTION_LIMIT,
    SET_DEFAULT_EXPRESSION_DATAMODEL,
    SET_DEFAULT_EXPRESSION_DATASET,
    SET_DEFAULT_EXPRESSION_DATA_FIELD,
    SET_DEFAULT_EXPRESSION_AGGREGATION,
    SET_DEFAULT_EXPRESSION_TRANSLATIONS,
    // Time
    SET_TIME_METRIC,
    SET_TIME_FORMAT,
    SET_TIME_LEVEL,
    // URL Param
    SET_URL_PARAM_ENABLED,
    SET_URL_PARAM_PARAM_FIELD,
    // Cards
    SET_APPLY_TO_ALL_CARDS,
    CLEAN_ALL_CARDS,
    SET_ALL_CARDS_DATAMODEL,
    SET_ALL_CARDS_DATASET,
    SET_ALL_CARDS_DATA_FIELD,
    ADD_CARD,
    REMOVE_CARD,
    ADD_CARD_QUERY,
    REMOVE_CARD_QUERY,
    SET_CARD_QUERY_DATAMODEL,
    SET_CARD_QUERY_DATASET,
    SET_CARD_QUERY_DATA_FIELD,
    // Queries
    ADD_QUERY,
    REMOVE_QUERY,
    SET_QUERY_DATAMODEL,
    SET_QUERY_DATASET,
    SET_QUERY_DATA_FIELD,
    // Affected filters
    ADD_ALL_AFFECTED_FILTERS,
    REMOVE_ALL_AFFECTED_FILTERS,
    ADD_AFFECTED_FILTER,
    REMOVE_AFFECTED_FILTER,
    ADD_AFFECTED_FILTER_AUTO_APPLY,
    REMOVE_AFFECTED_FILTER_AUTO_APPLY,
    // Filter use
    SET_SHOW_SELECTION_TAB,
    SET_DATE_LEVEL_SELECTION_TAB,
    SET_SHOW_EXPRESSION_TAB,
    SET_DATE_LEVEL_EXPRESSION_TAB,
    SET_OPERATIONS_ALLOWED_EXPRESSION_TAB,
    SET_SHOW_PREDEFINED_TAB,
    SET_DATE_LEVEL_PREDEFINED_TAB,
    SET_SHOW_TOP_BOTTOM_TAB
} from './filters-editor-dialog-actions';

// Initial state
const initialState = {
    filters: [],
    selectedFilterId: null,
    canSubmit: false,
    pageCardsMetadata: [],
    cardMetadata: [],
    showFilterTabs: false
};

const getFilterIndex = (filtersArray, selectedFilterId) => filtersArray.findIndex(f => f._id === selectedFilterId);

/**
 * Filters Editor Dialog Reducers
 */
const filtersEditorDialog = (state = initialState, action) => {
    let value = action.value;
    const data = action.data;
    const cardQueryIndex = action.cardQueryIndex;
    const queryIndex = action.queryIndex;
    let newState = cloneDeep(state);
    let newFilters = cloneDeep(newState.filters || []);
    let newValue, newFromValue, newToValue; // Time values
    const filterIndex = getFilterIndex(newFilters, newState.selectedFilterId);
    switch (action.type) {
        case INITIALIZE_FILTERS_EDITOR_DIALOG:
            return initialState;

        case SET_FILTERS:
            return setFilters(newState, action.filters, getFilterIndex(action.filters, newState.selectedFilterId));

        case SET_SELECTED_FILTER_ID:
            newState.selectedFilterId = action.selectedFilterId;
            return newState;

        case SET_PAGE_CARDS_METADATA:
            newState.pageCardsMetadata = action.pageCardsMetadata;
            return newState;

        case SET_CARD_METADATA:
            newState.cardMetadata = action.cardMetadata;
            return newState;

            // General setters

        case SET_NAME:
            set(newFilters[filterIndex], "name", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_FILTER_TYPE:
            set(newFilters[filterIndex], "filter_type", value);
            set(newFilters[filterIndex], "default_expression.values", []);
            if (value === "Field") {
                set(newFilters[filterIndex], "data_type", (newFilters[filterIndex] && newFilters[filterIndex].data_field && newFilters[filterIndex].data_field.data_type) || "string");
                set(newFilters[filterIndex], "time_metric", false);
                set(newFilters[filterIndex], "time_level", null);
                set(newFilters[filterIndex], "time_format", null);
                set(newFilters[filterIndex], "multi_value", true);
                set(newFilters[filterIndex], "default_expression.all_values", true);
                set(newFilters[filterIndex], "use_custom_list", false);
                set(newFilters[filterIndex], "custom_list", []);
                set(newFilters[filterIndex], "default_expression.operation", "inList");
                return setFilters(newState, newFilters, filterIndex);
            }
            if (value === "Boolean") {
                set(newFilters[filterIndex], "use_custom_list", false);
                set(newFilters[filterIndex], "custom_list", []);
            }
            set(newFilters[filterIndex], "data_type", value ? value.toLowerCase() : "string");
            set(newFilters[filterIndex], "time_metric", false);
            set(newFilters[filterIndex], "time_level", null);
            set(newFilters[filterIndex], "time_format", null);
            set(newFilters[filterIndex], "multi_value", false);
            set(newFilters[filterIndex], "default_expression.all_values", false);
            set(newFilters[filterIndex], "order", {});
            return setFilters(newState, newFilters, filterIndex);

        case SET_USE_CUSTOM_LIST:
            set(newFilters[filterIndex], "use_custom_list", value);
            set(newFilters[filterIndex], "default_expression.values", []);
            if (!value) {
                set(newFilters[filterIndex], "multi_value", false);
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_CUSTOM_LIST:
            set(newFilters[filterIndex], "custom_list", value);            
            set(newFilters[filterIndex], "use_custom_list", !!value && value.length > 0);
            if (!(!!value && value.length > 0)) {
                set(newFilters[filterIndex], "multi_value", false);
                set(newFilters[filterIndex], "default_expression.all_values", false);
            }
            if ((["between", "notBetween"]).indexOf(newFilters[filterIndex].default_expression.operation) === -1) {
                set(newFilters[filterIndex], "default_expression.values", newFilters[filterIndex].default_expression.values.filter(dev => value.some(v => v === dev)));
            }
            else {
                const fromValue = newFilters[filterIndex].default_expression.values[0];
                const toValue = newFilters[filterIndex].default_expression.values[1];
                if (!!fromValue && !value.some(v => v === fromValue)) newFilters = setDefaultExpressionFromValue(newFilters, filterIndex, "");
                if (!!toValue && !value.some(v => v === toValue)) newFilters = setDefaultExpressionToValue(newFilters, filterIndex, "");
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_MULTI_VALUE:
            set(newFilters[filterIndex], "multi_value", value);
            if (!value) {
                set(newFilters[filterIndex], "default_expression.all_values", false);
                set(newFilters[filterIndex], "default_expression.values", (
                    newFilters[filterIndex].default_expression.values.length > 0 &&
                    (["predefinedRange", "notPredefinedRange"]).indexOf(newFilters[filterIndex].default_expression.operation) === -1
                ) ? [newFilters[filterIndex].default_expression.values[0]] : []);
                set(newFilters[filterIndex], "default_expression.operation", "equals");
                return setFilters(newState, newFilters, filterIndex);
            }
            if ((["predefinedRange", "notPredefinedRange", "top", "bottom", "interval"]).indexOf(newFilters[filterIndex].default_expression.operation) !== -1) {
                set(newFilters[filterIndex], "default_expression.values", []);
            }
            set(newFilters[filterIndex], "default_expression.operation", "inList");
            return setFilters(newState, newFilters, filterIndex);

        case SET_VISIBLE:
            set(newFilters[filterIndex], "visible", value);
            if (!value) {
                set(newFilters[filterIndex], "editable", false);
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_EDITABLE:
            set(newFilters[filterIndex], "editable", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_MANDATORY:
            set(newFilters[filterIndex], "mandatory", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_STAGE:
            set(newFilters[filterIndex], "stage", value);
            return setFilters(newState, newFilters, filterIndex);

            // Filter translations
        case SET_TRANSLATIONS:
            set(newFilters[filterIndex], "i18n", value);
            return setFilters(newState, newFilters, filterIndex);

            // Url Params

        case SET_URL_PARAM_ENABLED:
            set(newFilters[filterIndex], "url_param.enabled", value);
            if (!!value) {
                set(newFilters[filterIndex], "editable", false);
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_URL_PARAM_PARAM_FIELD:
            set(newFilters[filterIndex], "url_param.param_field", value);
            return setFilters(newState, newFilters, filterIndex);

            // Origin setters

        case SET_DATAMODEL:
            set(newFilters[filterIndex], "datamodel", value);
            set(newFilters[filterIndex], 'dataset', null);
            set(newFilters[filterIndex], 'data_field', null);
            set(newFilters[filterIndex], 'default_expression.values', []);
            set(newFilters[filterIndex], 'time_metric', false);
            set(newFilters[filterIndex], 'time_level', null);
            set(newFilters[filterIndex], 'time_format', null);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DATASET:
            set(newFilters[filterIndex], "dataset", value);
            set(newFilters[filterIndex], 'data_field', null);
            set(newFilters[filterIndex], 'default_expression.values', []);
            set(newFilters[filterIndex], 'time_metric', false);
            set(newFilters[filterIndex], 'time_level', null);
            set(newFilters[filterIndex], 'time_format', null);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DATA_FIELD:
            set(newFilters[filterIndex], "data_field", value);
            set(newFilters[filterIndex], "default_expression.values", []);
            set(newFilters[filterIndex], "time_metric", false);
            set(newFilters[filterIndex], "time_level", null);
            set(newFilters[filterIndex], "time_format", null);
            if (value.data_type !== newFilters[filterIndex].data_type) {
                set(newFilters[filterIndex], "default_expression.operation", null);
                set(newFilters[filterIndex], "default_expression.date_level", undefined);
                set(newFilters[filterIndex], "data_type", value.data_type);
            }
            return setFilters(newState, newFilters, filterIndex);

            // Order setters

        case SET_ORDER:
            set(newFilters[filterIndex], "order", value);
            return setFilters(newState, newFilters, filterIndex);

            // Default expression setters

        case SET_DEFAULT_EXPRESSION_OPERATION:
            set(newFilters[filterIndex], "default_expression.operation", value);
            set(newFilters[filterIndex], 'default_expression.values', []);
            set(newFilters[filterIndex], 'default_expression.timeValue1', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValue2', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValue3', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValueMin1', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValueMin2', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValueMin3', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValueMax1', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValueMax2', undefined);
            set(newFilters[filterIndex], 'default_expression.timeValueMax3', undefined);
            if ((["predefinedRange", "notPredefinedRange"]).indexOf(value) !== -1) {
                set(newFilters[filterIndex], 'use_custom_list', false);
                set(newFilters[filterIndex], 'custom_list', []);
                set(newFilters[filterIndex], 'multi_value', false);
                set(newFilters[filterIndex], 'filter_use.predefined_tab.show', true);
            }
            if ((["top", "bottom", "interval"]).indexOf(value) !== -1) {
                set(newFilters[filterIndex], 'multi_value', false);
                set(newFilters[filterIndex], 'url_param.enabled', false);
                set(newFilters[filterIndex], 'filter_use.top_bottom_tab.show', true);
            }
            if ((["isNull", "isNotNull"]).indexOf(value) !== -1) {
                set(newFilters[filterIndex], 'url_param.enabled', false);
                // if boolean add  operation as value
                if (newFilters[filterIndex].data_type === 'boolean' || newFilters[filterIndex].filter_type === 'Boolean') {
                    newFilters = setDefaultExpressionValues(newFilters, filterIndex, [value]);
                }
            }

            // Set mandatory tab to show depending on operation
            if ((['equals', 'notEquals', 'inList', 'notInList']).indexOf(value) !== -1 && ((newFilters[filterIndex].filter_type && newFilters[filterIndex].filter_type === 'Field') || newFilters[filterIndex].use_custom_list)) {
                set(newFilters[filterIndex], 'filter_use.selection_tab.show', true);
            } else if (([
                'equals', 'notEquals', 'greaterThan', 'greaterOrEqualThan', 'smallerThan', 'smallerOrEqualThan', 'between', 'notBetween',
                'isNull', 'isNotNull', 'contains', 'notContains', 'startsWith', 'notStartsWith', 'endsWith', 'notEndsWith'
            ]).indexOf(value) !== -1) {
                set(newFilters[filterIndex], 'filter_use.expression_tab.show', true);
            }
  
            // Search new operation value in filter use
            if (newFilters[filterIndex].filter_use && newFilters[filterIndex].filter_use.expression_tab) {
                if (newFilters[filterIndex].filter_use.expression_tab.operations_allowed.length > 0 && !newFilters[filterIndex].filter_use.expression_tab.operations_allowed.find((opAllowed) => opAllowed === value)) {
                    let operationValues = cloneDeep(newFilters[filterIndex].filter_use.expression_tab.operations_allowed);
                    operationValues.push(value);
                    set(newFilters[filterIndex], "filter_use.expression_tab.operations_allowed", operationValues);
                }
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_DATE_LEVEL:
            set(newFilters[filterIndex], "default_expression.date_level", value);
            set(newFilters[filterIndex], 'default_expression.values', []);

            // Search new date level value in filter use
            if (newFilters[filterIndex].filter_use) {
                let tabs = ["selection_tab", "expression_tab", "predefined_tab"];
                tabs.forEach((tab) => {
                    if (newFilters[filterIndex].filter_use[tab] && newFilters[filterIndex].filter_use[tab].date_level) {
                        if (newFilters[filterIndex].filter_use[tab].date_level.length > 0 && !newFilters[filterIndex].filter_use[tab].date_level.find((dLevel) => dLevel === value)) {
                            let newDateLevelValues = cloneDeep(newFilters[filterIndex].filter_use[tab].date_level);
                            newDateLevelValues.push(value);
                            set(newFilters[filterIndex], `filter_use.${tab}.date_level`, newDateLevelValues);
                        }
                    }
                });
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_ALL_VALUES:
            set(newFilters[filterIndex], 'default_expression.all_values', value);
            if (!value) {
                set(newFilters[filterIndex], 'default_expression.values', []);
                return setFilters(newState, newFilters, filterIndex);
            }
            if (newFilters[filterIndex].data_type === 'boolean' || newFilters[filterIndex].filter_type === 'Boolean') {
                set(newFilters[filterIndex], 'default_expression.values', ["true", "false"]);
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_VALUES:
            newFilters = setDefaultExpressionValues(newFilters, filterIndex, value);
            return setFilters(newState, newFilters, filterIndex);
        
        case SET_DEFAULT_EXPRESSION_TRANSLATIONS:
            set(newFilters[filterIndex], 'default_expression.translations', value || []);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_VALUE:
            newFilters = setDefaultExpressionValue(newFilters, filterIndex, value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_FROM_VALUE:
            newFilters = setDefaultExpressionFromValue(newFilters, filterIndex, value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TO_VALUE:
            newFilters = setDefaultExpressionToValue(newFilters, filterIndex, value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_1:
            set(newFilters[filterIndex], 'default_expression.timeValue1', value);
            newValue = getValueFromTimeValues(newFilters[filterIndex]);
            newFilters = setDefaultExpressionValue(newFilters, filterIndex, newValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_2:
            set(newFilters[filterIndex], 'default_expression.timeValue2', value);
            newValue = getValueFromTimeValues(newFilters[filterIndex]);
            newFilters = setDefaultExpressionValue(newFilters, filterIndex, newValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_3:
            set(newFilters[filterIndex], 'default_expression.timeValue3', value);
            newFilters = setDefaultExpressionValue(newFilters, filterIndex, newValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_1:
            set(newFilters[filterIndex], 'default_expression.timeValueMin1', value);
            newFromValue = getValueFromTimeValueMins(newFilters[filterIndex]);
            newFilters = setDefaultExpressionFromValue(newFilters, filterIndex, newFromValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_2:
            set(newFilters[filterIndex], 'default_expression.timeValueMin2', value);
            newFromValue = getValueFromTimeValueMins(newFilters[filterIndex]);
            newFilters = setDefaultExpressionFromValue(newFilters, filterIndex, newFromValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_3:
            set(newFilters[filterIndex], 'default_expression.timeValueMin3', value);
            newFromValue = getValueFromTimeValueMins(newFilters[filterIndex]);
            newFilters = setDefaultExpressionFromValue(newFilters, filterIndex, newFromValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_1:
            set(newFilters[filterIndex], 'default_expression.timeValueMax1', value);
            newToValue = getValueFromTimeValueMaxs(newFilters[filterIndex]);
            newFilters = setDefaultExpressionToValue(newFilters, filterIndex, newToValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_2:
            set(newFilters[filterIndex], 'default_expression.timeValueMax2', value);
            newToValue = getValueFromTimeValueMaxs(newFilters[filterIndex]);
            newFilters = setDefaultExpressionToValue(newFilters, filterIndex, newToValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_3:
            set(newFilters[filterIndex], 'default_expression.timeValueMax3', value);
            newToValue = getValueFromTimeValueMaxs(newFilters[filterIndex]);
            newFilters = setDefaultExpressionToValue(newFilters, filterIndex, newToValue);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_OPTION_LIMIT:
            set(newFilters[filterIndex], "default_expression.options.limit", value);
            // todo 1036 call new filter data
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_DATAMODEL:
            set(newFilters[filterIndex], "default_expression.datamodel", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_DATASET:
            set(newFilters[filterIndex], "default_expression.dataset", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_DATA_FIELD:
            set(newFilters[filterIndex], "default_expression.data_field", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_DEFAULT_EXPRESSION_AGGREGATION:
            set(newFilters[filterIndex], "default_expression.aggregation", value);
            return setFilters(newState, newFilters, filterIndex);

            // Time setters

        case SET_TIME_METRIC:
            set(newFilters[filterIndex], "time_metric", value);
            if (value) {
                set(newFilters[filterIndex], "time_level", getTimeLevelOptions()[0].value);
                set(newFilters[filterIndex], "time_format", getDateTimeFormatOptions()[0].value);
            }
            else {
                set(newFilters[filterIndex], "time_level", null);
                set(newFilters[filterIndex], "time_format", null);
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_TIME_LEVEL:
            set(newFilters[filterIndex], "time_level", value);
            return setFilters(newState, newFilters, filterIndex);

        case SET_TIME_FORMAT:
            set(newFilters[filterIndex], "time_format", value);
            return setFilters(newState, newFilters, filterIndex);

            // Cards setters

        case SET_APPLY_TO_ALL_CARDS:
            set(newFilters[filterIndex], "apply_same_config_for_all_cards", value);
            if (value) {
                newState.pageCardsMetadata.forEach(cardMetaData => {
                    if (newFilters[filterIndex].cards.findIndex(card => card._id === cardMetaData._id) === -1) {                        
                        cardMetaData.queries.forEach(cardQuery => {
                            if (String(cardQuery?.datamodel) === String(data?.datamodel) && cardQuery.fields.length > 0) {
                                const cardQueryToAdd = {
                                    _id: cardMetaData._id,
                                    query: cardQuery.uuid,
                                    datamodel: data.datamodel
                                };
                                if (data.dataset) {
                                    cardQueryToAdd.dataset = data.dataset;
                                }
                                if (data.dataField) {
                                    cardQueryToAdd.data_field = data.dataField;
                                }
                                newFilters[filterIndex].cards.push(cardQueryToAdd);
                            }
                        });                        
                    }
                });
            }
            return setFilters(newState, newFilters, filterIndex);

        case CLEAN_ALL_CARDS:
            set(newFilters[filterIndex], "cards", []);
            return setFilters(newState, newFilters, filterIndex);

        case SET_ALL_CARDS_DATAMODEL:
            newState.pageCardsMetadata.forEach(cardMetaData => {
                cardMetaData.queries.forEach(cardQuery => {
                    if (String(cardQuery.datamodel) === String(value) && cardQuery.fields.length > 0) {
                        const cardQueryUuid = cardQuery.uuid;
                        const cardQueryToAdd = {
                            _id: cardMetaData._id,
                            query: cardQueryUuid,
                            datamodel: value
                        };
                        const filterCardQueryIndex = newFilters[filterIndex].cards.findIndex(filterCardQuery => {
                            return filterCardQuery._id === cardMetaData._id && filterCardQuery.query === cardQueryUuid;
                        });
                        if (filterCardQueryIndex === -1) {
                            newFilters[filterIndex].cards.push(cardQueryToAdd);
                        }
                        else if (value !== newFilters[filterIndex].cards[filterCardQueryIndex].datamodel) {
                            newFilters[filterIndex].cards[filterCardQueryIndex] = cardQueryToAdd;
                        }
                    }
                });
            });
            return setFilters(newState, newFilters, filterIndex);

        case SET_ALL_CARDS_DATASET:
            newFilters[filterIndex].cards.forEach((filterCardQuery, filterCardQueryIndex) => {
                if (String(filterCardQuery.datamodel) === String(data.datamodel)) {
                    if (value !== newFilters[filterIndex].cards[filterCardQueryIndex].dataset) {
                        newFilters[filterIndex].cards[filterCardQueryIndex].data_field = null;
                    }
                    newFilters[filterIndex].cards[filterCardQueryIndex].dataset = value;
                }
            });
            return setFilters(newState, newFilters, filterIndex);

        case SET_ALL_CARDS_DATA_FIELD:
            newFilters[filterIndex].cards.forEach((filterCardQuery, filterCardQueryIndex) => {
                if (String(filterCardQuery.datamodel) === String(data.datamodel) && String(filterCardQuery.dataset) === String(data.dataset)) {
                    newFilters[filterIndex].cards[filterCardQueryIndex].data_field = value;
                }
            });
            return setFilters(newState, newFilters, filterIndex);

        case ADD_CARD:
            value.forEach(cardQuery => newFilters[filterIndex].cards.push(cardQuery));
            return setFilters(newState, newFilters, filterIndex);

        case REMOVE_CARD:
            newFilters[filterIndex].cards = newFilters[filterIndex].cards.filter(cardQuery => cardQuery._id !== value);
            return setFilters(newState, newFilters, filterIndex);

        case ADD_CARD_QUERY:
            newFilters[filterIndex].cards.push(value);
            return setFilters(newState, newFilters, filterIndex);

        case REMOVE_CARD_QUERY:
            newFilters[filterIndex].cards.splice(cardQueryIndex, 1);
            return setFilters(newState, newFilters, filterIndex);

        case SET_CARD_QUERY_DATAMODEL:
            if (value !== newFilters[filterIndex].cards[cardQueryIndex].datamodel) {
                newFilters[filterIndex].cards[cardQueryIndex].datamodel = value;
                newFilters[filterIndex].cards[cardQueryIndex].dataset = null;
                newFilters[filterIndex].cards[cardQueryIndex].data_field = null;
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_CARD_QUERY_DATASET:
            if (value !== newFilters[filterIndex].cards[cardQueryIndex].dataset) {
                newFilters[filterIndex].cards[cardQueryIndex].dataset = value;
                newFilters[filterIndex].cards[cardQueryIndex].data_field = null;
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_CARD_QUERY_DATA_FIELD:
            if (value !== newFilters[filterIndex].cards[cardQueryIndex].data_field) {
                newFilters[filterIndex].cards[cardQueryIndex].data_field = value;
            }
            return setFilters(newState, newFilters, filterIndex);

            // Queries

        case ADD_QUERY:
            newFilters[filterIndex].queries.push(value);
            return setFilters(newState, newFilters, filterIndex);

        case REMOVE_QUERY:
            newFilters[filterIndex].queries.splice(queryIndex, 1);
            return setFilters(newState, newFilters, filterIndex);

        case SET_QUERY_DATAMODEL:
            if (value !== newFilters[filterIndex].queries[queryIndex].datamodel) {
                newFilters[filterIndex].queries[queryIndex].datamodel = value;
                newFilters[filterIndex].queries[queryIndex].dataset = null;
                newFilters[filterIndex].queries[queryIndex].data_field = null;
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_QUERY_DATASET:
            if (value !== newFilters[filterIndex].queries[queryIndex].dataset) {
                newFilters[filterIndex].queries[queryIndex].dataset = value;
                newFilters[filterIndex].queries[queryIndex].data_field = null;
            }
            return setFilters(newState, newFilters, filterIndex);

        case SET_QUERY_DATA_FIELD:
            if (value !== newFilters[filterIndex].queries[queryIndex].data_field) {
                newFilters[filterIndex].queries[queryIndex].data_field = value;
            }
            return setFilters(newState, newFilters, filterIndex);

            // Affected filters setters

        case ADD_ALL_AFFECTED_FILTERS:
            // Check all filters and add all valid filters
            for (const filter of newFilters) {
                // Check if filter is current filter or is parent or type is not "Field"
                if (
                    filter._id === newFilters[filterIndex]._id ||
                    filter.affected_filters.findIndex((filterId) => filterId === newFilters[filterIndex]._id) !== -1 ||
                    filter.filter_type !== "Field"
                ) continue;
                // Add valid filter to affected filters array
                newFilters[filterIndex].affected_filters.push(filter._id);
            }
            return setFilters(newState, newFilters, filterIndex);

        case REMOVE_ALL_AFFECTED_FILTERS:
            set(newFilters[filterIndex], "affected_filters", []);
            return setFilters(newState, newFilters, filterIndex);

        case ADD_AFFECTED_FILTER:
            newFilters[filterIndex].affected_filters.push(value);
            return setFilters(newState, newFilters, filterIndex);

        case REMOVE_AFFECTED_FILTER:
            newFilters[filterIndex].affected_filters = newFilters[filterIndex].affected_filters.filter(aFilter => aFilter !== value);
            if (newFilters[filterIndex].affected_filters_auto_apply && newFilters[filterIndex].affected_filters_auto_apply.length > 0) newFilters[filterIndex].affected_filters_auto_apply = newFilters[filterIndex].affected_filters_auto_apply.filter(aFilter => String(aFilter) !== String(value));
            return setFilters(newState, newFilters, filterIndex);

        case ADD_AFFECTED_FILTER_AUTO_APPLY:
            if (newFilters[filterIndex].affected_filters_auto_apply) {
                newFilters[filterIndex].affected_filters_auto_apply.push(value);
            } else {
                newFilters[filterIndex].affected_filters_auto_apply = [value];
            }

            return setFilters(newState, newFilters, filterIndex);

        case REMOVE_AFFECTED_FILTER_AUTO_APPLY:
            newFilters[filterIndex].affected_filters_auto_apply = newFilters[filterIndex].affected_filters_auto_apply.filter(aFilter => aFilter !== value);
            return setFilters(newState, newFilters, filterIndex);

        /** Filter use **/
        // Selection Tab
        case SET_SHOW_SELECTION_TAB:
            newFilters[filterIndex].filter_use.selection_tab.show = !newFilters[filterIndex].filter_use.selection_tab.show;
            return setFilters(newState, newFilters, filterIndex);
        case SET_DATE_LEVEL_SELECTION_TAB:
            newFilters[filterIndex].filter_use.selection_tab.date_level = value;
            return setFilters(newState, newFilters, filterIndex);

        // Expression Tab
        case SET_SHOW_EXPRESSION_TAB:
            newFilters[filterIndex].filter_use.expression_tab.show = !newFilters[filterIndex].filter_use.expression_tab.show;
            return setFilters(newState, newFilters, filterIndex);
        case SET_DATE_LEVEL_EXPRESSION_TAB:
            newFilters[filterIndex].filter_use.expression_tab.date_level = value;
            return setFilters(newState, newFilters, filterIndex);
        case SET_OPERATIONS_ALLOWED_EXPRESSION_TAB:
            newFilters[filterIndex].filter_use.expression_tab.operations_allowed = value;
            return setFilters(newState, newFilters, filterIndex);

        // Predefined Tab
        case SET_SHOW_PREDEFINED_TAB:
            newFilters[filterIndex].filter_use.predefined_tab.show = !newFilters[filterIndex].filter_use.predefined_tab.show;
            return setFilters(newState, newFilters, filterIndex);
        case SET_DATE_LEVEL_PREDEFINED_TAB:
            newFilters[filterIndex].filter_use.predefined_tab.date_level = value;
            return setFilters(newState, newFilters, filterIndex);

        // Top Bottom Tab
        case SET_SHOW_TOP_BOTTOM_TAB:
            newFilters[filterIndex].filter_use.top_bottom_tab.show = !newFilters[filterIndex].filter_use.top_bottom_tab.show;
            return setFilters(newState, newFilters, filterIndex);

        default:
            return state;
    }
};

export default filtersEditorDialog;

// Function that check if filter is valid or not
const checkFilter = (filter) => {
    let saveDisabled = false;

    // Check for all types
    if (!filter.default_expression || !filter.default_expression.operation) saveDisabled = true;

    // Check between and not between operations
    if (
        (filter.default_expression.operation === 'between' || filter.default_expression.operation === 'notBetween') &&
        (filter.default_expression.values[0] == null || filter.default_expression.values[1] == null)
    ) {
        saveDisabled = true;
        if (filter.filter_type === "Number" && (!numberValueIsValid(filter.default_expression.values[0]) ||
            !numberValueIsValid(filter.default_expression.values[1]) ||
            Number(filter.default_expression.values[1]) < Number(filter.default_expression.values[0]))) {
            saveDisabled = true;
        }
    }

    // Old check
    if (
        !filter.default_expression.values ||
        ((filter.data_type === 'date' || filter.data_type === 'datetime') && (!filter.default_expression.date_level && filter.default_expression.operation !== 'isNull' && filter.default_expression.operation !== 'isNotNull' ))  ||
        (filter.default_expression.values.length === 0 && !filter.default_expression.all_values && filter.default_expression.operation !== 'isNull' && filter.default_expression.operation !== 'isNotNull' )
    ) {
        saveDisabled = true;
    }

    // Check field filter
    if(filter.filter_type === 'Field' && (!filter.dataset || !filter.data_field)) {
        saveDisabled = true;
    }
    // Check date filter
    else if (filter.filter_type === "Date") {
        if ((["isNull", "isNotNull"]).indexOf(filter.default_expression.operation) !== -1) {}
        else if ((["predefinedRange", "notPredefinedRange"]).indexOf(filter.default_expression.operation) !== -1) {}
        else {
            if (!filter.default_expression.date_level || !Array.isArray(filter.default_expression.values)) saveDisabled = true;
            else {
                const defaultExpressionValueFormat = getDateInputFormat(filter.default_expression.date_level);
                if (!filter.default_expression.all_values && filter.default_expression.values.some(dev => !dateValueIsValid({ format: defaultExpressionValueFormat, value: dev }))) saveDisabled = true;
            }
        }
    }
    // Check number filter
    else if (filter.filter_type === "Number") {
        if ((["isNull", "isNotNull"]).indexOf(filter.default_expression.operation) !== -1 || filter.default_expression.all_values) {}
        else {
            if (!Array.isArray(filter.default_expression.values)) saveDisabled = true;
            else if (filter.default_expression.values.some(dev => !numberValueIsValid(dev))) saveDisabled = true;
        }
    }

    return !saveDisabled;
};

// Function to check filters changes
const setFilters = (newState, newFilters, filterIndex) => {

    // Check all filters for canSubmit
    newFilters = newFilters.map(filter => ({
        ...filter,
        isValid: checkFilter(filter)
    }));
    newState.canSubmit = newFilters.reduce((numInvalidFilters, filter) => filter.isValid ? numInvalidFilters : numInvalidFilters + 1, 0) > 0;

    // Change selected filter if needed
    if (newFilters.length === 0) {
        delete(newState.selectedFilterId);
    } else if (filterIndex === -1) {
        newState.selectedFilterId = newFilters[0]._id;
    }

    // Set new filters
    newState.filters = newFilters;

    // Set show filter tabs
    newState.showFilterTabs = newState.filters.length > 0;

    return newState;
};

// Set default expression values helpers
const setDefaultExpressionValues = (newFilters, filterIndex, value) => {
    // Sanity check
    if (!Array.isArray(value)) value = [];
    // Parse booleans
    if ((newFilters[filterIndex].data_type === 'boolean' || newFilters[filterIndex].filter_type === 'Boolean') && (["isNull", "isNotNull"]).indexOf(value && value[0]) === -1) {
        value = value.map(v => String(v === i18n.t("common.yes")));
    }
    // Parse numbers
    if ((newFilters[filterIndex].data_type === 'number' || newFilters[filterIndex].filter_type === 'Number')) {
        value = value.map(v => v === "" ? v : Number(v));
    }
    set(newFilters[filterIndex], 'default_expression.values', value);

    // If filter is parent, delete value from child filters
    if (Array.isArray(newFilters[filterIndex].affected_filters)) {
        newFilters[filterIndex].affected_filters.forEach(affectedFilterId => {
            const childFilterIndex = getFilterIndex(newFilters, affectedFilterId);
            // setDefaultExpressionValues(newFilters, childFilterIndex, []);
            set(newFilters[childFilterIndex], 'default_expression.values', []);
            set(newFilters[childFilterIndex], 'default_expression.date_level', undefined);
        });
    }
    return newFilters;
};
const setDefaultExpressionValue = (newFilters, filterIndex, value) => {
    return setDefaultExpressionValues(newFilters, filterIndex, [value]);
};
const setDefaultExpressionFromValue = (newFilters, filterIndex, value) => {
    const newDefaultExpressionValues = cloneDeep(newFilters[filterIndex].default_expression.values);
    newDefaultExpressionValues[0] = value;
    return setDefaultExpressionValues(newFilters, filterIndex, newDefaultExpressionValues);
};
const setDefaultExpressionToValue = (newFilters, filterIndex, value) => {
    const newDefaultExpressionValues = cloneDeep(newFilters[filterIndex].default_expression.values);
    newDefaultExpressionValues[1] = value;
    return setDefaultExpressionValues(newFilters, filterIndex, newDefaultExpressionValues);
};

// Time values helpers
const getValueFromTimeMetricValue = (filter, key) => {
    const parts = [];
    for (let i = 1; i >= 3; i++) {
        parts.push(filter.default_expression[`${key}${i}`] || 0);
    }
    return String(getTimeMetricValue(getTimeExpressionFromParts(parts, filter.time_format), filter.time_level, filter.time_format));
}
const getValueFromTimeValues = filter => getValueFromTimeMetricValue(filter, "timeValue");
const getValueFromTimeValueMins = filter => getValueFromTimeMetricValue(filter, "timeValueMin");
const getValueFromTimeValueMaxs = filter => getValueFromTimeMetricValue(filter, "timeValueMax");