import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Loading(props) {

    const { t } = useTranslation();

    // Props
    const { show, applyElapse, elapsedTime, message, backgroundColor, labelColor, dialogIsOpen } = props;

    // State
    const [showLoadingTimeout, setShowLoadingTimeout] = useState();
    const [showLoading, setShowLoading] = useState(false);

    // Show loading logic
    useEffect(() => {
        let newShowLoadingTimeout;
        if (show) {
            newShowLoadingTimeout = setTimeout(() => {
                setShowLoading(true);
            }, [applyElapse ? elapsedTime : 0]);
            setShowLoadingTimeout(newShowLoadingTimeout);
        }
        else {
            setShowLoadingTimeout(clearTimeout(showLoadingTimeout));
            setShowLoading(false);
        }
        return () => {
            clearInterval(newShowLoadingTimeout || showLoadingTimeout);
        }
    }, [show]);

    if (!showLoading) return null;

    const styles = getLoadingStyles(dialogIsOpen, backgroundColor, labelColor);

    return (
        <div className="loading" style={styles.root}>
            <div style={styles.loaderContainer}>
                <div className="progress-circle-indeterminate" style={{display: 'flex'}} />
                <div style={styles.label}>{message || t('common.loadingLabel')}</div>
            </div>
        </div>
    );

}

const getLoadingStyles = (dialogIsOpen, backgroundColor, labelColor) => {
    return {
        root: {
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: backgroundColor ? backgroundColor : 'rgba(0,0,0,0.15)',
            width: '100%',
            height: dialogIsOpen ? getDialogHeight('100%') : '100%'
        },
        loaderContainer: {
            position: 'relative',
            width: 150,
            top: '50%',
            margin: '0 auto',
            transform: 'translateY(-50%)'
        },
        label: {
            position: 'absolute',
            bottom: -20,
            width: '100%',
            color: labelColor ? labelColor : '#fff',
            fontSize: 12,
            textAlign: 'center'
        }
    };
}

const getDialogHeight = (defaultHeight) => {
    const el = document.querySelectorAll('[role="dialog"]');
    if (el && el[0] && el[0].clientHeight) return el[0].clientHeight;
    return defaultHeight;
};