import { PROFILES_FULL } from "../security/security-gql";

export const COLLECTIONS_LIST_PAGE = `
    _id
    name
    description
    status
    theme
    items {
        content_id
        name
        type
    }
    ${PROFILES_FULL}
    created
    updated
    resource_token
`;


export const COLLECTION_DETAIL = `
    _id
    name
    description
    status
    theme
    navigation {
        type
        position
    }
    items {
        content_id
        name
        type
    }
    space {
        _id
        name
        space_type
        theme
        i18n {
            language
            field
            value
        }
    }
    ${PROFILES_FULL}
    resource_token
    i18n {
        language
        field
        value
    }
`;

export const COLLECTIONS_LIST_BASIC = `
    _id
    name
    description
    status
`;

export const SPACE_COLLECTIONS_LIST = `
    _id
    name
    description
    theme
    items {
        content_id
        name
        type
    }
    resource_token
    ${PROFILES_FULL}
    updated
    created
    i18n {
        language
        field
        value
    }
`;