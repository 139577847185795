import { CARDS_LIST_BASIC, CARDS_OPTIONS_FILTERS_DETAIL, CARD_QUERIES_FULL } from '../cards/cards-gql';
import { FILTERS_DETAIL, FILTERS_LIST_BASIC } from "../../data/filters/filters-gql";
import { PROFILES_FULL } from "../security/security-gql";

export const PAGES_LIST = `
    _id
    name
    desc
    theme
    cards {
        _id
    }
    filters {
        _id
    }
    auto_apply_filters
    show_filters_area
    show_row_limit_warning
    resource_token
    demo
    created
    updated
    i18n {
        language
        field
        value
    }
    ${PROFILES_FULL}
`;

export const PAGES_LIST_BASIC = `
    _id
    name
    desc
    theme
    created
    updated
    resource_token
`;

export const PAGES_MINIMAL = `
    _id
    name
`;

export const PAGES_MINIMAL_FILTERS = `
    _id
    name
    filters {
        ${FILTERS_DETAIL}
    }
`;

export const PAGES_DETAIL = `
    _id
    name
    desc
    theme
    structure
    auto_apply_filters
    show_filters_area
    show_row_limit_warning
    resource_token
    demo
    created
    updated
    space_id {
        _id
        name
        space_type
        theme
        i18n {
            language
            field
            value
        }
    }
    cards {
        ${CARDS_LIST_BASIC}
    }
    filters {
        ${FILTERS_LIST_BASIC}
    }
    i18n {
        language
        field
        value
    }
    ${PROFILES_FULL}
`;

export const PAGES_CARDS_FILTERS_DETAIL = `
    _id
    name
    desc
    theme
    auto_apply_filters
    show_filters_area
    show_row_limit_warning
    resource_token
    demo
    created
    updated
    structure
    space_id {
        _id
        name
        space_type
    }
    cards {
        ${CARDS_OPTIONS_FILTERS_DETAIL}
    }
    filters {
        ${FILTERS_DETAIL}
    }
    i18n {
        language
        field
        value
    }
    ${PROFILES_FULL}
`;

export const PAGES_FILTERS_DETAIL = `
    _id
    name
    desc
    theme
    auto_apply_filters
    show_filters_area
    show_row_limit_warning
    resource_token
    demo
    created
    updated
    structure
    space_id {
        _id
        name
        space_type
        theme
        i18n {
            language
            field
            value
        }
    }
    cards {
        _id
        name
        multi_query
        queries {
            ${CARD_QUERIES_FULL}
        }
    }
    filters {
        ${FILTERS_DETAIL}
    }
    i18n {
        language
        field
        value
    }
    ${PROFILES_FULL}
`;

export const PAGES_FILTERS_DETAIL_SHARE = `
    _id
    name
    desc
    theme
    auto_apply_filters
    show_filters_area
    show_row_limit_warning
    resource_token
    demo
    created
    updated
    structure
    space_id {
        _id
        theme
    }
    cards {
        _id
    }
    filters {
        ${FILTERS_DETAIL}
    }
    profile_level
    i18n {
        language
        field
        value
    }
`;

export const PAGES_ACTIONS_DIALOG = `
    _id
    name
    space_id {
        _id
    }
    cards {
        _id
    }
    filters {
        ${FILTERS_DETAIL}
    }
`;

export const PAGES_FILTERS_LIST = `
    _id
    name
    desc
    theme
    resource_token
    filters {
        ${FILTERS_DETAIL}
    }
`;

export const SPACE_PAGES_LIST = `
    _id
    name
    desc
    theme
    auto_apply_filters
    show_filters_area
    cards {
        _id
    }
    resource_token
    ${PROFILES_FULL}
    updated
    created
    i18n {
        language
        field
        value
    }
`;