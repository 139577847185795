import { useDispatch } from 'react-redux';

import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from "@biuwer/core/src/i18n";
import History from "@biuwer/core/src/history";
import gqlRequest from '@biuwer/core/src/graphql-request';

// Actions
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { defaultContext } from "@biuwer/redux/src/config/constants";
import { PAGES_CARDS_FILTERS_DETAIL, PAGES_DETAIL, PAGES_LIST, SPACE_PAGES_LIST } from "./pages-gql";
import { DATAFILTERS } from "../../data/filters/filters-gql";

/*
 * Pages action types
 */
export const PAGE_INITIALIZE = 'PAGE_INITIALIZE';
export const PAGES_INITIALIZE = 'PAGES_INITIALIZE';
export const PAGE_LIST_INITIALIZE = 'PAGE_LIST_INITIALIZE';
export const PAGE_DETAIL_INITIALIZE = 'PAGE_DETAIL_INITIALIZE';

export const PAGES_FETCH_REQUEST = 'PAGES_FETCH_REQUEST';
export const PAGES_FETCH_SUCCESS = 'PAGES_FETCH_SUCCESS';
export const PAGES_FETCH_ERROR = 'PAGES_FETCH_ERROR';

export const PAGE_FETCH_REQUEST = 'PAGE_FETCH_REQUEST';
export const PAGE_FETCH_SUCCESS = 'PAGE_FETCH_SUCCESS';
export const PAGE_FETCH_ERROR = 'PAGE_FETCH_ERROR';

export const PAGE_UPDATE_REQUEST = 'PAGE_UPDATE_REQUEST';
export const PAGE_UPDATE_SUCCESS = 'PAGE_UPDATE_SUCCESS';
export const PAGE_UPDATE_ERROR = 'PAGE_UPDATE_ERROR';

export const PAGE_MOVE_REQUEST = 'PAGE_MOVE_REQUEST';
export const PAGE_MOVE_SUCCESS = 'PAGE_MOVE_SUCCESS';
export const PAGE_MOVE_ERROR = 'PAGE_MOVE_ERROR';

export const PAGE_DUPLICATE_REQUEST = 'PAGE_DUPLICATE_REQUEST';
export const PAGE_DUPLICATE_SUCCESS = 'PAGE_DUPLICATE_SUCCESS';
export const PAGE_DUPLICATE_ERROR = 'PAGE_DUPLICATE_ERROR';

export const PAGE_CREATE_REQUEST = 'PAGES_CREATE_REQUEST';
export const PAGE_CREATE_SUCCESS = 'PAGES_CREATE_SUCCESS';
export const PAGE_CREATE_ERROR = 'PAGES_CREATE_ERROR';

export const PAGE_DELETE_REQUEST = 'PAGES_DELETE_REQUEST';
export const PAGE_DELETE_SUCCESS = 'PAGES_DELETE_SUCCESS';
export const PAGE_DELETE_ERROR = 'PAGES_DELETE_ERROR';

export const PAGE_FILTERS_UPSERT_REQUEST = 'PAGE_FILTERS_UPSERT_REQUEST';
export const PAGE_FILTERS_UPSERT_SUCCESS = 'PAGE_FILTERS_UPSERT_SUCCESS';
export const PAGE_FILTERS_UPSERT_ERROR = 'PAGE_FILTERS_UPSERT_ERROR';

export const PAGE_FILTERS_CLEAN_REQUEST = 'PAGE_FILTERS_CLEAN_REQUEST';
export const PAGE_FILTERS_CLEAN_SUCCESS = 'PAGE_FILTERS_CLEAN_SUCCESS';
export const PAGE_FILTERS_CLEAN_ERROR = 'PAGE_FILTERS_CLEAN_ERROR';

export const PAGE_STRUCTURE_ACTION = 'PAGE_STRUCTURE_ACTION';
export const PAGE_STRUCTURE_ACTION_RESET = 'PAGE_STRUCTURE_ACTION_RESET';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, extraArgs, showNotification = true) => {
    let skeleton = { type, context }, notification;

    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    // code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type){
        case PAGE_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('pages.nameLabel'), context: 'female', count: 1 })
            };

            break;
        case PAGE_UPDATE_REQUEST:
            skeleton.pageId = extraArgs.pageId;

            break;
        case PAGE_UPDATE_SUCCESS:
            skeleton.updated = true;
            skeleton.pageId = extraArgs.pageId;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('pages.nameLabel'), context: 'female', count: 1 })
            };

            if (extraArgs.goToPreviousRoute) history.goBack();

            break;
        case PAGE_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('pages.nameLabel'), context: 'female', count: 1 })
            };

            break;
        case PAGE_FETCH_REQUEST:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_FETCH_SUCCESS:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_FETCH_ERROR:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_FILTERS_UPSERT_REQUEST:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_FILTERS_UPSERT_SUCCESS:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_FILTERS_UPSERT_ERROR:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_DUPLICATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.duplicateSuccess', { name: i18n.t('pages.nameLabel'), context: 'female', count: 1 })
            };
            break;
        case PAGE_MOVE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.moveSuccess', { name: i18n.t('pages.nameLabel'), context: 'female', count: 1 })
            };
            break;
        case PAGE_CREATE_ERROR:
        case PAGE_DELETE_ERROR:
        case PAGE_DUPLICATE_ERROR:
        case PAGE_MOVE_ERROR:
        case PAGE_UPDATE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

/*
 * Action creators
 */

/**
 * Initialize Page
 */
export const initializePage = (context = defaultContext) => {
    return {
        type: PAGE_INITIALIZE,
        context
    };
};

/**
 * Initialize Pages
 */
export const initializePages = (context = defaultContext, baseContext) => {
    return {
        type: PAGES_INITIALIZE,
        context,
        baseContext
    };
};

export const initializeListPage = (context = defaultContext) => {
    return {
        type: PAGE_LIST_INITIALIZE,
        context
    };
};

export const initializeDetailPage = (context = defaultContext) => {
    return {
        type: PAGE_DETAIL_INITIALIZE,
        context
    }
};

/**
* Get Pages action
*/
export const queryPages = (query = {}, context = defaultContext, gql = PAGES_LIST) => {
   return async dispatch => {

       try {

           const gqlQuery = {
               query: `query($query: QueryPageInput!) {
                    readPages(query: $query) {
                        ${gql}
                    }
               }`,
               variables: {
                   query: query
               }
           };

           const token = Auth.getLocalJwt();
           dispatch(generateSkeleton(PAGES_FETCH_REQUEST, null, context));

           const res = await request
               .post('/api/gql/')
               .send(gqlQuery)
               .set('Authorization', `Bearer ${token}`)
               .on('response', (response) => Auth.checkResponse(response));

           if (res) {
                if (res.body && res.body.data && res.body.data.readPages) {
                    dispatch(generateSkeleton(PAGES_FETCH_SUCCESS, res.body.data.readPages, context));
                }
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAGES_FETCH_ERROR, res.body.errors[0], context));
                }
           }

       } catch (err) {
           dispatch(generateSkeleton(PAGES_FETCH_ERROR, err, context));
       }
   };
};

/**
 * Create page action
 */
export const createPage = (page, context = defaultContext, gql = PAGES_DETAIL) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAGE_CREATE_REQUEST, null, context));

            const createdPage = await gqlRequest({
                queryType: "mutation",
                queryName: "createPage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "PageInput!",
                    name: "page",
                    data: page
                }]
            });

            dispatch(generateSkeleton(PAGE_CREATE_SUCCESS, createdPage, context));
            return createdPage;
        } catch (err) {
            dispatch(generateSkeleton(PAGE_CREATE_ERROR, err, context));
        }
    };
};

/**
 * Update page action
 */
export const updatePage = (page, context = defaultContext, gql = PAGES_DETAIL, goToPreviousRoute) => {
    return async dispatch => {
        try {
            let pageToUpdate = {
                name: page.name,
                desc: page.desc,
                theme: page.theme,
                auto_apply_filters: page.auto_apply_filters,
                show_filters_area: page.show_filters_area,
                structure: page.structure,
                show_row_limit_warning: page.show_row_limit_warning,
                i18n: page.i18n
            };

            if (page.filters) {
                pageToUpdate.filters = page.filters;
            }

            if (page.cards) {
                pageToUpdate.cards = page.cards.map(card => card._id || card);
            }

            delete(pageToUpdate._id);

            dispatch(generateSkeleton(PAGE_UPDATE_REQUEST, null, context, { pageId: page._id }));

            const updatedPage = await gqlRequest({
                queryType: "mutation",
                queryName: "updatePage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(page._id)
                }, {
                    type: "PageInput!",
                    name: "page",
                    data: pageToUpdate
                }, {
                    type: "[String]",
                    name: "filtersToCheck",
                    data: page.filtersToCheck || null
                }]
            });

            dispatch(generateSkeleton(PAGE_UPDATE_SUCCESS, updatedPage, context, { pageId: page._id, goToPreviousRoute }));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_UPDATE_ERROR, err, context, { pageId: page._id }));
        }
    };
};


/**
 * Move page action
 */
export const movePage = (pageId, options, context = defaultContext, gql = PAGES_DETAIL) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAGE_MOVE_REQUEST, null, context, { pageId }));

            const movedPage = await gqlRequest({
                queryType: "mutation",
                queryName: "movePage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(pageId)
                }, {
                    type: "Float!",
                    name: "newParent",
                    data: Number(options.newParentId)
                }, {
                    type: "String!",
                    name: "childAction",
                    data: options.childAction
                }]
            });

            dispatch(generateSkeleton(PAGE_MOVE_SUCCESS, movedPage, context, { pageId }));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_MOVE_ERROR, err, context, { pageId }));
        }
    };
};


/**
 * Duplicate page action
 */
export const duplicatePage = (pageId, options, context = defaultContext, gql = PAGES_DETAIL) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAGE_DUPLICATE_REQUEST, null, context, { pageId }));

            const duplicatedPage = await gqlRequest({
                queryType: "mutation",
                queryName: "duplicatePage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: pageId
                }]
            });

            dispatch(generateSkeleton(PAGE_DUPLICATE_SUCCESS, duplicatedPage, context, { pageId }));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_DUPLICATE_ERROR, err, context, { pageId }));
        }
    };
};

/**
 * Delete page action
 */
export const deletePage = (pageId, context = defaultContext, gql = PAGES_DETAIL) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAGE_DELETE_REQUEST, null, context));

            const deletedPage = await gqlRequest({
                queryType: "mutation",
                queryName: "deletePage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(pageId)
                }]
            });

            dispatch(generateSkeleton(PAGE_DELETE_SUCCESS, deletedPage, context));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_DELETE_ERROR, err, context));
        }
    };
};

/**
 * Get Page action
 */
export const getPage = (pageId, context = defaultContext, gql = PAGES_CARDS_FILTERS_DETAIL) => {

    return async dispatch => {

        try {

            const gqlQuery = {
                query: `query($_id: Float!) {
                    readPage(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: Number(pageId)
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(PAGE_FETCH_REQUEST, null, context, { pageId }));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readPage) {
                    dispatch(generateSkeleton(PAGE_FETCH_SUCCESS, res.body.data.readPage, context, { pageId }));
                }
                else if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAGE_FETCH_ERROR, res.body.errors[0], context, { pageId }));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PAGE_FETCH_ERROR, err, context, { pageId }));
        }
    };
};


/**
 * Upsert page filters action
 */
export const upsertPageFilters = (datafilters, pageId, context = defaultContext, gql = DATAFILTERS) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAGE_FILTERS_UPSERT_REQUEST, null, context, { pageId }));

            if (Auth.getAuthApp() === "share") {

                // Redirect new filters to reducer, don't upsert in bd
                return dispatch(generateSkeleton(PAGE_FILTERS_UPSERT_SUCCESS, datafilters, context, { pageId }));
            }

            const upsertPageFiltersResult = await gqlRequest({
                queryType: "mutation",
                queryName: "upsertDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "[JSONObject!]!",
                    name: "datafilters",
                    data: datafilters
                }]
            });

            dispatch(generateSkeleton(PAGE_FILTERS_UPSERT_SUCCESS, upsertPageFiltersResult, context, { pageId }));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_FILTERS_UPSERT_ERROR, err, context, { pageId }));
        }

    };
};

export const cleanPageFilters = (objectType, object, datafilters, pageId, context = defaultContext, gql = DATAFILTERS) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAGE_FILTERS_CLEAN_REQUEST, null, context, { pageId }));

            if (Auth.getAuthApp() === "share") {

                // Redirect new filters to reducer, don't upsert in bd
                return dispatch(generateSkeleton(PAGE_FILTERS_CLEAN_SUCCESS, datafilters, context, { pageId }));
            }

            const cleanPageFiltersResult = await gqlRequest({
                queryType: "mutation",
                queryName: "cleanDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "objectType",
                    data: objectType.toLowerCase()
                }, {
                    type: "JSONObject!",
                    name: "object",
                    data: object
                }]
            });

            dispatch(generateSkeleton(PAGE_FILTERS_CLEAN_SUCCESS, cleanPageFiltersResult, context, { pageId }));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_FILTERS_CLEAN_ERROR, err, context, { pageId }));
        }
    };
};

export const pageStructureAction = (item, context = defaultContext) => {

    return {
        type: PAGE_STRUCTURE_ACTION,
        payload: item,
        context: context
    };
};

export const pageStructureActionReset = (context = defaultContext) => {

    return {
        type: PAGE_STRUCTURE_ACTION_RESET,
        context: context
    };
};

export const usePagesActions = () => {

    const dispatch = useDispatch();

    return {
        initializePages: async (context, baseContext) => dispatch(initializePages(context, baseContext)),
        getSpacePages: async (spaceId, context, gql = SPACE_PAGES_LIST) => dispatch(queryPages({ space_id: spaceId }, context, gql)),
        getCardPages: async (cardId, context, gql) => dispatch(queryPages({ cards: Number(cardId) }, context, gql)),
        createPage: async (page, context, gql) => dispatch(createPage(page, context, gql)),
        updatePage: async (page, context, gql) => dispatch(updatePage(page, context, gql)),
        duplicatePage: async (pageId, context, gql = SPACE_PAGES_LIST) => dispatch(duplicatePage(pageId, null, context, gql)),
        deletePage: async (pageId, context, gql = SPACE_PAGES_LIST) => dispatch(deletePage(pageId, context, gql)),
    };

};